// Body

@function hexToRgb($hex) {
  $red: red($hex);
  $green: green($hex);
  $blue: blue($hex);
  @return $red, $green, $blue;
}
@function hexToRgba($hex, $alpha) {
  $red: red($hex);
  $green: green($hex);
  $blue: blue($hex);
  @return rgba($red, $green, $blue, $alpha);
}

$color-primary: #f8931f;
//$color-primary: #616095;
$color-primary-rgb: hexToRgb($color-primary);
$color-primary-hover: darken($color-primary, 15%);
$color-primary-hover-rgb: hexToRgb($color-primary-hover);
$color-success: #1cbb8c;

:root {
  /** Colors */
  --gyg-color-primary: #{$color-primary};
  --gyg-color-primary-hover: #{$color-primary-hover};
  --gyg-color-danger: #ff3d60;
  --gyg-color-danger-rgb: 255, 61, 96;
  /** Custom Colors */
  --gyg-font-sans-serif: "Nunito", sans-serif;
  --gyg-body-font-size: 0.9rem;
  --gyg-body-bg: #f1f5f7;
  --gyg-body-color: #212529;
  --gyg-secondary-color: rgba(33, 37, 41, 0.75);
  --gyg-secondary-bg: #fff;
  --gyg-heading-color: #252b3b;
  --gyg-border-color-translucent: rgba(0, 0, 0, 0.175);
  --gyg-btn-bg: $color-primary;
  --gyg-btn-bg: $color-primary;
  --gyg-border-color: #f1f5f7;
  --gyg-form-invalid-color: var(--gyg-color-danger);
  --gyg-form-invalid-border-color: var(--gyg-color-danger);
  --gyg-danger-rgb: var(--gyg-color-danger-rgb);
  --gyg-link-color: #{$color-primary};
  --gyg-link-color-rgb: #{$color-primary-rgb};
  --gyg-link-hover-color: #{$color-primary-hover};
  --gyg-link-hover-color-rgb: #{$color-primary-hover-rgb};
  --gyg-primary-rgb: #{$color-primary-rgb};
  --gyg-btn-close-focus-shadow: none;
  /**Sidebar */
  --gyg-sidebar-menu-item-color: #74788d;
  --gyg-sidebar-menu-item-hover-color: #383c40;
  /**Card */
  --gyg-card-border-color: var(--gyg-border-color);
  /**Bg */
  --gyg-success-bg-subtle: #d2f1e8;
  --gyg-success-rgb: 28,187,140;
  --gyg-danger-bg-subtle: #ffd8df;
  /**Pagination**/
  --gyg-pagination-active-bg: var(--gyg-color-primary);
  --gyg-pagination-active-border-color: var(--gyg-color-primary);
  /**Form */
  --gyg-form-valid-border-color: #{$color-success};
  --gyg-form-valid-color: #{$color-success};
}
