.layout__auth{
    background: #fff;
}

.auth-form-group-custom {
    position: relative;
    .auti-custom-input-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 19px;
        font-size: 24px;
        color: $color-primary;
    }
    label {
        position: absolute;
        top: 7px;
        left: 60px;
    }
    .form-control {
        height: 60px;
        padding-left: 60px;
        padding-top: 28px;
    }
}

.authentication-bg {
    background-image: url('../../assets/fondo.jpg');
    background-position: 50%;
    background-size: cover;
    height: 100vh;
}
.bg-overlay {
    background-color: #292626;
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: .7;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}
.img-logo{
    width: 40%;
}

@media (max-width: 990px) {
    .authentication-bg {
        display: none;
    }
}